<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="mt-5">
        <b-alert show variant="warning">
          <i class="ri-alert-line h4 mr-3"></i>
          <h5>Este módulo se encuentra en desarrollo</h5>
        </b-alert>
      </b-col>
      <b-col cols="12">
          <b-img :src="require('@/assets/images/mantenimiento.jpg')" fluid  alt="Responsive image"></b-img>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import { Arabic } from 'flatpickr/dist/l10n/ar'
import { english } from 'flatpickr/dist/l10n/default'
import { mapGetters } from 'vuex'
// import { db, firebaseApp } from '../../config/firebase'
export default {
  name: 'DashboardAdmin',
  mounted () {
    core.index()
  },
  watch: {
    rtl: function (value) {
      this.options.rtl = this.rtl
    }
  },
  computed: {
    ...mapGetters({
      rtl: 'Setting/rtlModeState',
      lang: 'Setting/langState'
    })
  },
  methods: {
  },
  data () {
    return {
      config: {
        dateFormat: 'Y-m-d',
        inline: true,
        locale: english
      },
      arConfig: {
        dateFormat: 'Y-m-d',
        inline: true,
        locale: Arabic
      },
      items: [],
      properties: null,
      identifiers: null
    }
  },
  firestore () {
    // var user = firebaseApp.auth().currentUser
    // console.log('El current user es:', user)
    // var query = db.collection('AAB/' + localStorage.userDocId + '/orders').where('referenceUserId', '==', localStorage.uid)
    // // console.log('Juan vea la Query', query)
    // query.get().then((querySnapshot) => {
    //   querySnapshot.forEach((doc) => {
    //     this.items.push(doc.data())
    //     console.log(doc.id, ' => ', doc.data())
    //   })
    // })
    //   .catch((error) => {
    //     console.log('Error getting documents: ', error)
    //   })

    // var query2 = db.collection('AAB/ujS2KXInLNHYgPXonH0P/contacts').doc(localStorage.userDocId)
    // console.log('Juan vea la nueva Query', query2)
    // query2.get().then((doc) => {
    //   if (doc.exists) {
    //     this.properties = Object.values(doc.data().companies)
    //     this.identifiers = Object.keys(doc.data().companies)
    //   } else {
    //     // doc.data() will be undefined in this case
    //     console.log('No such document!, nos equivocamos Juan')
    //   }
    // }).catch((error) => {
    //   console.log('Error getting document:', error)
    // })
  }
}
</script>
<style>
#post-slider .slick-arrow{
  top: 25px;
}
#post-slider .slick-arrow.left {
    left: auto;
    right: 100px;
}
.slick-arrow.right {
    right: 65px;
    top: 0;
}
</style>
